<template>
  <div class="card">
    <header v-if="title" class="card-header">
      <div class="card-header-title">
        <b-icon v-if="icon" :icon="icon" custom-size="default" />
        {{ title }}
      </div>
      <a
        v-if="headerIcon"
        href="#"
        class="card-header-icon"
        aria-label="delete"
        @click.prevent="headerIconClick"
      >
        <b-icon :icon="headerIcon" type="is-danger" custom-size="default" />
      </a>
      <a
        v-if="printIcon"
        class="card-header-icon"
        aria-label="print"
        @click.prevent="printIconClick"
      >
        <b-icon :icon="printIcon" type="is-dark" custom-size="default" />
      </a>
    </header>
    <slot name="right" />
    <div class="card-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  props: {
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    headerIcon: {
      type: String,
      default: null
    },
    printIcon: {
      type: String,
      default: null
    }
  },
  methods: {
    headerIconClick () {
      this.$emit('header-icon-click')
    },
    printIconClick () {
      this.$emit('print-icon-click')
    }
  }
}
</script>
