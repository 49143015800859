import mutations from '@/store/mutations'
import actions from '@/store/actions'

const endpoint = '/users'

const state = {
  formError: false,
  pwdFormError: false,
  per_page: 10,
  current_page: 1,
  total: 0,
  items: [],
  item: {},
  filter: '',
  sortDirection: 'asc'
}

const getters = {
  store: (state) => {
    return state
  }
}

const User = {
  namespaced: true,
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions(endpoint)
}

export default User
