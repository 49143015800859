import mutations from '@/store/mutations'
import actions from '@/store/actions'

const endpoint = '/categories'

const state = {
  loading: false,
  formError: true,
  per_page: 10,
  current_page: 1,
  total: 0,
  items: [],
  item: {},
  fields: [
    { key: 'id', label: 'ID', class: 'text-center' },
    { key: 'name', label: 'Name', class: 'text-center' },
    { key: 'slug', label: 'Slug', class: 'text-center d-none d-sm-table-cell' },
    { key: 'consumers_count', label: 'Consumers', class: 'text-center' },
    { key: 'mfc', label: 'MFC', class: 'text-center d-none d-sm-table-cell' },
    { key: 'updated_at', label: 'Updated', class: 'text-center' },
    { key: 'actions', label: 'Actions' }
  ],
  filter: '',
  sortDirection: 'asc'
}

const getters = {
  store: (state) => {
    return state
  }
}

const Category = {
  namespaced: true,
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions(endpoint)
}

export default Category
