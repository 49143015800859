import mutations from '@/store/mutations'
import actions from '@/store/actions'

const endpoint = '/tariffs'

const state = {
  loading: false,
  formError: true,
  per_page: 10,
  current_page: 1,
  total: 0,
  items: [],
  item: {},
  filter: ''
}

const getters = {
  store: (state) => {
    return state
  }
}

const Tariff = {
  namespaced: true,
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions(endpoint)
}

export default Tariff
