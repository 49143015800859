export function authHeader () {
  // return authorization header with jwt token
  let user
  try {
    user = JSON.parse(localStorage.getItem('user'))
  } catch (e) {
    localStorage.removeItem('user')
  }
  if (user) {
    if (user.access_token) {
      return {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: 'Bearer ' + user.access_token
      }
    }
  } else {
    return {
      'Content-Type': 'application/json; charset=UTF-8'
    }
  }
}
