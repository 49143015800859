<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import update from '@/mixins/update'
export default {
  name: 'Home',
  mixins: [update],
  computed: {
    ...mapState('alert', ['message'])
  },
  watch: {
    message (newMsg) {
      if (newMsg) {
        this.showMessage(newMsg)
      }
    },
    updateExists (newStatus) {
      if (newStatus) {
        this.showUpdate('An update is available!')
      }
    }
  },
  methods: {
    ...mapActions({ clearAlert: 'alert/clear' }),
    showMessage (msg) {
      const notif = this.$buefy.snackbar.open({
        duration: 2000,
        message: msg,
        type: 'is-warning',
        position: 'is-bottom-right',
        actionText: 'Close',
        queue: false,
        onAction: () => {
          this.clearAlert()
        }
      })
      notif.$on('close', () => {
        this.clearAlert()
      })
    },
    showUpdate (msg) {
      this.$buefy.snackbar.open({
        indefinite: true,
        message: msg,
        type: 'is-success',
        position: 'is-bottom',
        actionText: 'Update',
        queue: false,
        onAction: () => {
          this.refreshApp()
        }
      })
    }
  }
}
</script>
