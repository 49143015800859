import { crudService, apiService } from '@/services'

function actions (endpoint) {
  return {
    fetchItems ({ commit, dispatch }, params) {
      const ctx = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      commit('SET_FILTER', params.filter)
      commit('SET_LOADING', true)
      return crudService.getAll(endpoint, ctx).then((response) => {
        commit('SET_LOADING', false)
        commit('SET_PER_PAGE', response.data.per_page)
        commit('SET_CURRENT_PAGE', response.data.current_page)
        commit('SET_TOTAL', response.data.total)
        commit('SET_ITEMS', response.data.data)
      }).catch((error) => {
        commit('SET_LOADING', false)
        dispatch('error/show', error, { root: true })
      })
    },
    getItem ({ commit, dispatch }, id) {
      return crudService.getById(endpoint, id).then((response) => {
        commit('SET_ITEM', response.data)
      }).catch((error) => dispatch('error/show', error, { root: true }))
    },
    addItem ({ commit, dispatch }, item) {
      commit('SET_FORM_ERROR', true)
      return crudService.create(endpoint, item).then((response) => {
        commit('SET_FORM_ERROR', false)
        commit('ADD_ITEM', response.data)
        dispatch('alert/success', 'Resource Added.', { root: true })
      }).catch((error) => {
        commit('SET_FORM_ERROR', true)
        dispatch('error/show', error, { root: true })
      })
    },
    deleteItem ({ commit, dispatch }, id) {
      return crudService.delete(endpoint, id).then((response) => {
        if (response.status === 204) {
          dispatch('alert/success', 'Resource Deleted.', { root: true })
          commit('REMOVE_ITEM', id)
          commit('SET_CURRENT_PAGE', 1)
        }
      }).catch((error) => dispatch('error/show', error, { root: true }))
    },
    updateItem ({ commit, dispatch }, updatedItem) {
      commit('SET_FORM_ERROR', true)
      return crudService.update(endpoint, updatedItem).then((response) => {
        commit('SET_FORM_ERROR', false)
        commit('SET_ITEM', response.data)
        dispatch('alert/success', 'Resource Updated.', { root: true })
      }).catch((error) => dispatch('error/show', error, { root: true }))
    },
    setFilter ({ commit }, payload) {
      commit('SET_FILTER', payload)
    },
    changePassword ({ commit, dispatch }, item) {
      commit('SET_PWD_FORM_ERROR', true)
      apiService.changePwd(endpoint, item).then(() => {
        commit('SET_PWD_FORM_ERROR', false)
        dispatch('alert/success', 'Password Updated.', { root: true })
      }).catch((error) => dispatch('error/show', error, { root: true }))
    }
  }
}

export default actions
