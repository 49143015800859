// BarChart.js
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: ['labels', 'values'],
  mounted () {
    // Overwriting base render method with actual data.
    this.render()
  },
  methods: {
    render () {
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
            label: 'Tariffs',
            backgroundColor: '#F14668',
            data: this.values
          }
        ]
      }, {
        responsive: true,
        legend: {
          display: true
        }
      })
    }
  },
  watch: {
    labels () {
      this.render()
    },
    values () {
      this.render()
    }
  }
}
