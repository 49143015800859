import { apiService } from '@/services'
import router from '@/router'

const user = JSON.parse(localStorage.getItem('user'))

const state = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null }

const Auth = {
  namespaced: true,
  state: {
    ...state,
    rootUrl: '/dashboard'
  },
  mutations: {
    LOGIN_REQUEST (state, user) {
      state.status = { loggingIn: true }
      state.user = user
    },
    LOGIN_SUCCESS (state, user) {
      state.status = { loggedIn: true }
      state.user = user
    },
    LOGIN_FAILURE (state) {
      state.status = {}
      state.user = null
    },
    LOGOUT (state) {
      state.status = {}
      state.user = null
    },
    REGISTER_REQUEST (state) {
      state.status = { registering: true }
    },
    REGISTER_SUCCESS (state) {
      state.status = {}
    },
    REGISTER_FAILURE (state) {
      state.status = {}
    }
  },
  actions: {
    login ({ dispatch, commit }, { email, password }) {
      commit('LOGIN_REQUEST', { email })
      const creds = { email: email, password: password }
      return apiService.login('/login', creds).then(res => {
        if (res.status === 200) {
          const user = res.data
          if (user.access_token) {
            localStorage.setItem('user', JSON.stringify(user))
          }
          commit('LOGIN_SUCCESS', user)
          router.replace('/dashboard').then(() => dispatch('alert/success', 'Login Success!', { root: true }))
        }
      }).catch((error) => {
        commit('LOGIN_FAILURE')
        dispatch('error/show', error, { root: true })
      })
    },
    async logout ({ dispatch, commit }) {
      commit('LOGOUT')
      await apiService.logout()
    },
    register ({ dispatch, commit }, user) {
      commit('REGISTER_REQUEST', user)
      apiService.register('/register', user).then(user => {
        commit('REGISTER_SUCCESS', user)
        router.replace('/login')
        setTimeout(() => {
          dispatch('alert/success', 'Registration successful', { root: true })
        })
      }).catch((error) => {
        if (error.response) {
          commit('LOGIN_FAILURE', error.response.data)
          const errors = error.response.data
          for (const key in errors) {
            dispatch('alert/error', errors[key][0], { root: true })
          }
        } else {
          commit('LOGIN_FAILURE', error.message)
          dispatch('alert/error', error.message, { root: true })
        }
      })
    }
  }
}

export default Auth
