import axios from 'axios'
import { authHeader } from '../helpers'

export const apiService = {
  getAll,
  postAll,
  login,
  register,
  logout,
  getFile,
  postWithFile
}

const apiUrl = process.env.VUE_APP_API_URL

function login (endpoint, user) {
  axios.defaults.headers = authHeader()
  return axios.post(apiUrl + endpoint, user)
}

function register (endpoint, user) {
  axios.defaults.headers = authHeader()
  return axios.post(apiUrl + endpoint, user)
}

function logout () {
  // remove user from local storage to log user out
  localStorage.removeItem('user')
}

function getAll (endpoint) {
  axios.defaults.headers = authHeader()
  return axios.get(apiUrl + endpoint)
}

function postAll (endpoint, data) {
  axios.defaults.headers = authHeader()
  return axios.post(apiUrl + endpoint, data)
}

function getFile (endpoint, data) {
  axios.defaults.headers = authHeader()
  return axios.post(apiUrl + endpoint, data, { responseType: 'blob' })
}

function postWithFile (endpoint, data) {
  var headers = authHeader()
  headers['Content-Type'] = 'multipart/form-data'
  axios.defaults.headers = headers

  var formData = new FormData()
  for (const prop in data) {
    formData.append(prop, data[prop])
  }
  return axios.post(apiUrl + endpoint, formData)
}
