import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    meta: {
      title: 'Login'
    },
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    meta: {
      title: 'Refreshing Token'
    },
    path: '/refresh',
    component: () => import(/* webpackChunkName: "refresh" */ '../views/Refresh.vue')
  },
  {
    meta: {
      title: 'Consumer Tariff'
    },
    path: '/consumertariff/:id',
    name: 'consumertariff',
    component: () => import(/* webpackChunkName: "consumertariff" */ '../views/SSIPLENGG/biller/ShowTariff.vue')
  },
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/layouts/DashboardLayout.vue'),
    children: [
      {
        // Document title tag
        // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
        meta: {
          title: 'Dashboard'
        },
        path: '/',
        name: 'home',
        component: Home
      },
      {
        meta: {
          title: 'Biller'
        },
        path: 'biller',
        name: 'biller',
        component: () => import(/* webpackChunkName: "biller" */ '../views/SSIPLENGG/biller/Biller.vue')
      },
      {
        meta: {
          title: 'User Meters'
        },
        path: 'usermeters/:id',
        name: 'usermeters',
        component: () => import(/* webpackChunkName: "usermeters" */ '../views/SSIPLENGG/biller/UserMeters.vue')
      },
      {
        // Document title tag
        // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
        meta: {
          title: 'User'
        },
        path: 'users',
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/layouts/ParentLayout.vue'),
        children: [
          {
            meta: {
              title: 'Users'
            },
            path: '',
            name: 'users',
            component: () => import(/* webpackChunkName: "users" */ '../views/admin/Users.vue')
          },
          {
            meta: {
              title: 'Edit User'
            },
            path: 'edit/:id',
            name: 'user.edit',
            component: () => import(/* webpackChunkName: "user-form" */ '../views/admin/UserForm.vue'),
            props: true
          },
          {
            meta: {
              title: 'New User'
            },
            path: 'new/user',
            name: 'user.new',
            component: () => import(/* webpackChunkName: "user-form" */ '../views/admin/UserForm.vue'),
            props: true
          },
          {
            meta: {
              title: 'Edit Role'
            },
            path: 'roles/:id',
            name: 'role.edit',
            component: () => import(/* webpackChunkName: "role-form" */ '../views/admin/RoleForm.vue'),
            props: true
          },
          {
            meta: {
              title: 'New Role'
            },
            path: 'role/new',
            name: 'role.new',
            component: () => import(/* webpackChunkName: "role-form" */ '../views/admin/RoleForm.vue'),
            props: true
          },
          {
            meta: {
              title: 'Edit Permission'
            },
            path: 'permissions/:id',
            name: 'permission.edit',
            component: () => import(/* webpackChunkName: "permission-form" */ '../views/admin/PermissionForm.vue'),
            props: true
          },
          {
            meta: {
              title: 'New permission'
            },
            path: 'permission/new',
            name: 'permission.new',
            component: () => import(/* webpackChunkName: "permission-form" */ '../views/admin/PermissionForm.vue'),
            props: true
          }
        ]
      },
      {
        meta: {
          title: 'Regions'
        },
        path: 'regions',
        name: 'regions',
        component: () => import(/* webpackChunkName: "region" */ '../views/SSIPLENGG/regions/Regions.vue')
      },
      {
        meta: {
          title: 'Edit Region'
        },
        path: 'regions/:id',
        name: 'region.edit',
        component: () => import(/* webpackChunkName: "region-form" */ '../views/SSIPLENGG/regions/RegionForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'New Region'
        },
        path: 'region/new',
        name: 'region.new',
        component: () => import(/* webpackChunkName: "region-form" */ '../views/SSIPLENGG/regions/RegionForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'Meters'
        },
        path: 'meters',
        name: 'meters',
        component: () => import(/* webpackChunkName: "meter" */ '../views/SSIPLENGG/meters/Meters.vue')
      },
      {
        meta: {
          title: 'Edit Meter'
        },
        path: 'meters/:id',
        name: 'meter.edit',
        component: () => import(/* webpackChunkName: "meter-form" */ '../views/SSIPLENGG/meters/MeterForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'New Meter'
        },
        path: 'meter/new',
        name: 'meter.new',
        component: () => import(/* webpackChunkName: "meter-form" */ '../views/SSIPLENGG/meters/MeterForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'Tariffs'
        },
        path: 'tariffs',
        name: 'tariffs',
        component: () => import(/* webpackChunkName: "tariff" */ '../views/SSIPLENGG/tariffs/Tariffs.vue')
      },
      {
        meta: {
          title: 'Edit Tariff'
        },
        path: 'tariffs/:id',
        name: 'tariff.edit',
        component: () => import(/* webpackChunkName: "tariff-form" */ '../views/SSIPLENGG/tariffs/TariffForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'New Tariff'
        },
        path: 'tariff/new',
        name: 'tariff.new',
        component: () => import(/* webpackChunkName: "tariff-form" */ '../views/SSIPLENGG/tariffs/TariffForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'Print Tariff'
        },
        path: 'print/tariffs/:id',
        name: 'tariff.print',
        beforeEnter (to) { location.href = 'https://api.ssiplengg.com/print/' + to.params.id },
        props: true
      },
      {
        meta: {
          title: 'Tariff Types'
        },
        path: 'tarifftypes',
        name: 'tarifftypes',
        component: () => import(/* webpackChunkName: "tarifftype" */ '../views/SSIPLENGG/tarifftypes/Tarifftypes.vue')
      },
      {
        meta: {
          title: 'Edit Tariff'
        },
        path: 'tarifftypes/:id',
        name: 'tarifftype.edit',
        component: () => import(/* webpackChunkName: "tarifftype-form" */ '../views/SSIPLENGG/tarifftypes/TarifftypeForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'New Tariff Type'
        },
        path: 'tarifftype/new',
        name: 'tarifftype.new',
        component: () => import(/* webpackChunkName: "tarifftype-form" */ '../views/SSIPLENGG/tarifftypes/TarifftypeForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'Codes'
        },
        path: 'codes',
        name: 'codes',
        component: () => import(/* webpackChunkName: "code" */ '../views/SSIPLENGG/codes/Codes.vue')
      },
      {
        meta: {
          title: 'Edit Code'
        },
        path: 'codes/:id',
        name: 'code.edit',
        component: () => import(/* webpackChunkName: "code-form" */ '../views/SSIPLENGG/codes/CodeForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'New Code'
        },
        path: 'code/new',
        name: 'code.new',
        component: () => import(/* webpackChunkName: "code-form" */ '../views/SSIPLENGG/codes/CodeForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'Categories'
        },
        path: 'categories',
        name: 'categories',
        component: () => import(/* webpackChunkName: "category" */ '../views/SSIPLENGG/categories/Categories.vue')
      },
      {
        meta: {
          title: 'Edit Category'
        },
        path: 'categories/:id',
        name: 'category.edit',
        component: () => import(/* webpackChunkName: "category-form" */ '../views/SSIPLENGG/categories/CategoryForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'New Category'
        },
        path: 'category/new',
        name: 'category.new',
        component: () => import(/* webpackChunkName: "category-form" */ '../views/SSIPLENGG/categories/CategoryForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'Consumers'
        },
        path: 'consumers',
        name: 'consumers',
        component: () => import(/* webpackChunkName: "consumer" */ '../views/SSIPLENGG/consumers/Consumers.vue')
      },
      {
        meta: {
          title: 'Edit Consumer'
        },
        path: 'consumers/:id',
        name: 'consumer.edit',
        component: () => import(/* webpackChunkName: "consumer-form" */ '../views/SSIPLENGG/consumers/ConsumerForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'New Consumer'
        },
        path: 'consumer/new',
        name: 'consumer.new',
        component: () => import(/* webpackChunkName: "consumer-form" */ '../views/SSIPLENGG/consumers/ConsumerForm.vue'),
        props: true
      },
      {
        meta: {
          title: 'Reports'
        },
        path: 'reports',
        name: 'reports',
        component: () => import(/* webpackChunkName: "reports" */ '../views/SSIPLENGG/Reports.vue')
      },
      {
        meta: {
          title: 'Profile'
        },
        path: 'profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
      },
      {
        meta: {
          title: 'About'
        },
        path: 'about',
        name: 'about',
        component: () => import(/* webpackChunkName: "profile" */ '../views/admin/About.vue')
      }
    ]
  },
  {
    // Catch all 404
    path: '*',
    redirect: 'dashboard'
  }

]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
