import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './modules/auth.store'
import { alert } from './modules/alert.store'
import { error } from './modules/error.store'
import Code from './modules/codes.store'
import Role from './modules/roles.store'
import Category from './modules/categories.store'
import Permission from './modules/permissions.store'
import User from './modules/users.store'
import Region from './SSIPLENGG/regions.store'
import Tarifftype from './SSIPLENGG/tarifftypes.store'
import Consumer from './SSIPLENGG/consumers.store'
import Meter from './SSIPLENGG/meters.store'
import Tariff from './SSIPLENGG/tariff.store'
import Report from './SSIPLENGG/reports.store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,
    userMenu: [
      'General',
      [
        {
          to: '/dashboard',
          icon: 'desktop-mac',
          label: 'Dashboard'
        }
      ]
    ],

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
      if (payload.menu) {
        state.userMenu = payload.menu
      }
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    }
  },
  actions: {

  },
  modules: {
    auth: Auth,
    user: User,
    role: Role,
    permission: Permission,
    code: Code,
    region: Region,
    tarifftype: Tarifftype,
    category: Category,
    consumer: Consumer,
    tariff: Tariff,
    meter: Meter,
    report: Report,
    alert,
    error
  }
})
