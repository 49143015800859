<template>
  <div>
    <section class="section is-main-section">
      <tiles v-if="kpis.length">
        <card-widget
          v-for="kp in kpis"
          v-bind:key="kp.key"
          class="tile is-child"
          :type="kp.type"
          :icon="kp.icon"
          :number="kp.value"
          :label="kp.label"
        />
      </tiles>

      <div class="columns">
        <div class="column is-10 is-full-mobile">
          <card-component
            title="Bills Generated in Last 12 Months"
            icon="finance"
            header-icon="reload"
          >
            <div class="chart-area">
              <bar-chart :height="150" :labels="labels" :values="values" />
            </div>
          </card-component>

        </div>
        <div>
          <div class="column">
            <b-table
                :data="statistics"
                :columns="columns"
                bordered
                striped
                hoverable
              >
              </b-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import CardComponent from '@/components/CardComponent'
import { apiService } from '@/services'
import { mapActions } from 'vuex'
import BarChart from '@/components/Charts/BarChart'

export default {
  name: 'Home',
  components: {
    BarChart,
    CardComponent,
    CardWidget,
    Tiles
  },
  data () {
    return {
      labels: [],
      values: [],
      statistics: [],
      kpis: [
        {
          label: 'Regions',
          key: 'states',
          value: 0,
          icon: 'sign-real-estate',
          type: 'is-primary'
        },
        {
          label: 'Codes',
          key: 'codes',
          value: 0,
          icon: 'code',
          type: 'is-success'
        },
        {
          label: 'Consumers',
          key: 'customers',
          value: 0,
          icon: 'account-multiple',
          type: 'is-success'
        },
        {
          label: 'Meters',
          key: 'meters',
          value: 0,
          icon: 'account-multiple',
          type: 'is-primary'
        },
        {
          label: 'Tariffs',
          key: 'tariffs',
          value: 0,
          icon: 'lifebuoy',
          type: 'is-danger'
        }
      ],
      columns: [
        { field: 'key', label: 'Month' },
        { field: 'value', label: 'Value' }
      ]
    }
  },
  mounted () {
    this.getKpis()
  },
  methods: {
    ...mapActions({ showError: 'error/show' }),
    getKpis () {
      apiService.getAll('/kpis').then(response => {
        this.kpis = response.data
      }).catch(error => {
        this.showError(error)
      })

      apiService.getAll('/tariffsbymonth').then(response => {
        this.labels = response.data.labels
        this.values = response.data.values
        this.statistics = this.labels.map((label, index) => ({ key: label, value: this.values[index] }))
      }).catch(error => {
        this.showError(error)
      })
    }
  }
}
</script>
