export default {
  SET_FORM_ERROR: (state, formError) => (state.formError = formError),
  SET_LOADING: (state, payload) => (state.loading = payload),
  SET_PWD_FORM_ERROR: (state, formError) => (state.pwdFormError = formError),
  SET_PER_PAGE: (state, perPage) => (state.per_page = perPage),
  SET_CURRENT_PAGE: (state, currentPage) => (state.current_page = currentPage),
  SET_TOTAL: (state, total) => (state.total = total),
  SET_ITEMS: (state, items) => (state.items = items),
  SET_ITEM: (state, item) => (state.item = item),
  ADD_ITEM: (state, newItem) => state.items.unshift(newItem),
  REMOVE_ITEM: (state, id) => (state.items = state.items.filter((item) => item.id !== id)),
  UPDATE_ITEM: (state, updatedItem) => {
    const index = state.items.findIndex(item => item.id === updatedItem.id)
    if (index !== -1) state.items.splice(index, 1, updatedItem)
  },
  SET_FILTER: (state, filter) => (state.filter = filter),
  SET_STATES: (state, items) => (state.stateitems = items),
  SET_CITIES: (state, items) => (state.codes = items),
  SET_ROLES: (state, items) => (state.roles = items),
  SET_CATEGORIES: (state, categories) => (state.categories = categories)
}
